import { useState } from "react";
import { Form, Input, Typography, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import HypeButton from "./common/HypeButton/HypeButton";
import logoBlack from "./assets/logoBlack.png";

const { Title } = Typography;

export default function ResetPassword() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setError("");
    const { username, password, confirmationCode } = values;

    try {
      const response = await axios.post(
        "https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/confirmForgotPassword",
        {
          username,
          password,
          confirmationCode,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        navigate("/login");
      } else {
        setError("An error occurred while processing your request.");
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "An error occurred while processing your request."
      );
    }
  };

  if (success) {
    return (
      <div style={{ maxWidth: 400, margin: "auto", padding: "20px" }}>
        <Title level={2}>Password reset successful!</Title>
        <p>
          Your password has been reset successfully. Please click the link below
          to log in.
        </p>
        <Link to="/login">Log in</Link>
      </div>
    );
  }

  return (
    <div
      style={{
        width: 500,
        margin: "auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <img
        src={logoBlack}
        alt="logo"
        style={{ width: "60%", marginBottom: "40px" }}
      />
      <Title level={2}>Reset Password</Title>
      <Form onFinish={handleSubmit} style={{ width: "100%" }}>
        <Form.Item
          style={{ marginBottom: "0.75rem" }}
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input placeholder="Username" size="large" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: "0.75rem" }}
          name="confirmationCode"
          rules={[
            { required: true, message: "Please enter the security code!" },
          ]}
        >
          <Input placeholder="Security code" size="large" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: "0.75rem" }}
          name="password"
          rules={[
            { required: true, message: "Please enter your new password!" },
          ]}
        >
          <Input.Password placeholder="New Password" size="large" />
        </Form.Item>
        <Form.Item>
          <HypeButton name="Reset Password" htmlType="submit" />
        </Form.Item>
      </Form>
      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
}
