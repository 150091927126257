import { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { Form, Input, Alert, Button } from "antd";
import logoBlack from "./assets/logoBlack.png";
import HypeButton from "./common/HypeButton/HypeButton";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { user, signIn } = useContext(AuthContext);

  const handleSubmit = async (values) => {
    setError("");
    const { username, password } = values;

    try {
      await signIn(username, password);
    } catch (err) {
      setError(err.message);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        width: 500,
        margin: "auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <img
        src={logoBlack}
        alt="logo"
        style={{ width: "60%", marginBottom: "40px" }}
      />
      <Form onFinish={handleSubmit} style={{ width: "100%" }}>
        <Form.Item
          style={{ width: "100%", marginBottom: "0.75rem" }}
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input
            size="large"
            style={{ width: "100%", borderColor: "#545456" }}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input.Password
            size="large"
            style={{ width: "100%", borderColor: "#545456" }}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item style={{ marginBottom: "0.75rem" }}>
            <HypeButton htmlType="submit" name="Login" />
          </Form.Item>

          <Link to={"/signup"}>
            <Button
              type="link"
              name="Sign up"
              style={{ color: "black", marginRight: "-10px" }}
            >
              Sign up
            </Button>
          </Link>
        </div>
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <Link
            to={"/forgot-password"}
            name="Forgot password"
            style={{
              width: "130px",
              marginLeft: "auto",
            }}
          >
            <Button
              type="link"
              name="Forgot password"
              style={{
                color: "black",
              }}
            >
              Forgot password
            </Button>
          </Link>
        </div>
      </Form>
      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
}
