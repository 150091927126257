import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import ChatMain from "./ChatMain/ChatMain";
import Login from "./Login";
import SignUp from "./SignUp";
import ConfirmSignUp from "./CondirmSignUp";
import RouteGuard from "./RouteGuard";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <RouteGuard>
                  <ChatMain />
                </RouteGuard>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/confirm-sign-up" element={<ConfirmSignUp />} />
          </Routes>
        </main>
      </BrowserRouter>
    </AuthProvider>
  );
}
export default App;
