import { useState } from "react";
import { confirmSignUp } from "./auth";
import { Form, Input, Typography, Alert } from "antd";
import { Link, redirect } from "react-router-dom";
import HypeButton from "./common/HypeButton/HypeButton";
import logoBlack from "./assets/logoBlack.png";

const { Title } = Typography;

export default function ConfirmSignUp() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    setError("");
    const { username, code } = values;

    try {
      await confirmSignUp(username, code);
      setSuccess(true);
      redirect("/login");
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <div style={{ maxWidth: 400, margin: "auto", padding: "20px" }}>
        <Title level={2}>Confirmation successful!</Title>
        <p>You can now log in with your credentials. Go rock that app!</p>
        <Link to="/login">Login</Link>
      </div>
    );
  }

  return (
    <div
      style={{
        width: 500,
        margin: "auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <img
        src={logoBlack}
        alt="logo"
        style={{ width: "60%", marginBottom: "40px" }}
      />
      <Title level={2}>Confirm Sign Up</Title>

      <Form
        onFinish={handleSubmit}
        style={{ width: "100%", marginBottom: "0.75rem" }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input placeholder="Username" size="large" />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            { required: true, message: "Please enter the confirmation code!" },
          ]}
        >
          <Input placeholder="Confirmation code" size="large" />
        </Form.Item>
        <Form.Item>
          <HypeButton htmlType="submit" name="Confirm" />
        </Form.Item>
      </Form>
      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
}
