import { Button } from "antd";
import React from "react";
import "./HypeButton.css";

const HypeButton = (props) => {
  return (
    <Button
      type={"primary"}
      htmlType={props.htmlType}
      block
      className="hype-btn"
      size="large"
    >
      {props.name}
    </Button>
  );
};

export default HypeButton;
