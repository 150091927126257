import { useState } from "react";
import { signUp } from "./auth";
import { Form, Input, Typography, Alert } from "antd";
import { Link, redirect } from "react-router-dom";
import HypeButton from "./common/HypeButton/HypeButton";
import logoBlack from "./assets/logoBlack.png";

const { Title } = Typography;

export default function SignUp() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    setError("");
    const { username, email, password } = values;

    try {
      await signUp(username, email, password);
      setSuccess(true);
      redirect("/confirm-sign-up");
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <div style={{ maxWidth: 400, margin: "auto", padding: "20px" }}>
        <Title level={2}>Sign Up successful!</Title>
        <p>
          Please check your email for the confirmation code and visit the link
          below.
        </p>
        <Link to="/confirm-sign-up">Confirm your account</Link>
      </div>
    );
  }

  return (
    <div
      style={{
        width: 500,
        margin: "auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <img
        src={logoBlack}
        alt="logo"
        style={{ width: "60%", marginBottom: "40px" }}
      />
      <Title level={2}>Sign up</Title>
      <Form onFinish={handleSubmit} style={{ width: "100%" }}>
        <Form.Item
          style={{ marginBottom: "0.75rem" }}
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input placeholder="Username" size="large" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: "0.75rem" }}
          name="email"
          rules={[{ required: true, message: "Please enter your email!" }]}
        >
          <Input placeholder="Email" size="large" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: "0.75rem" }}
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input.Password placeholder="Password" size="large" />
        </Form.Item>
        <Form.Item>
          <HypeButton name="Sign up" htmlType="submit" />
        </Form.Item>
      </Form>
      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
}
