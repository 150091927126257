import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Layout,
  Input,
  Button,
  Typography,
  Avatar,
  Modal,
  Dropdown,
  Menu,
  List,
  Spin,
  message as antdMessage,
} from "antd";
import {
  SendOutlined,
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./ChatMain.css";
import { AuthContext } from "../AuthContext";
import logoWhite from "../assets/logoWhite.png";
import HypeLoader from "../common/HypeLoader/HypeLoader";
import parse from "html-react-parser";

import * as solidIcons from "@fortawesome/free-solid-svg-icons";

const { Header, Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 15);
};

const formatDateTime = (date) => {
  const pad = (num) => (num < 10 ? `0${num}` : num);
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1); // Months are 0-based
  const year = date.getFullYear();
  return `${hours}:${minutes} ${day}.${month}.${year}`; // Updated format
};

const replaceResourceKeys = (message, resources) => {
  let formattedMessage = message;
  for (const [key, url] of Object.entries(resources.images || {})) {
    const regex = new RegExp(`\\[${key}\\]`, "g");
    formattedMessage = formattedMessage.replace(
      regex,
      `<a href="${url}" target="_blank">[${key}]</a>`
    );
  }
  return formattedMessage;
};

export const ChatMain = () => {
  const { user, signOut } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [userData, setUser] = useState({ name: "John Doe" });
  const [chatHistory, setChatHistory] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [currentChatSessionId, setCurrentChatSessionId] = useState(null);
  const [chatIdToDelete, setChatIdToDelete] = useState(null);
  const chatEndRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (user) {
      fetchChatHistory();
    }
    if (!currentChatSessionId) {
      const newChatId = generateRandomId();
      setCurrentChatSessionId(newChatId);
      setCurrentChatId(null);
    }
  }, [user, currentChatSessionId]);

  const fetchChatHistory = async () => {
    const userId = user?.username || "default_user_id";
    try {
      const response = await fetch(
        `https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/getSessions?user_id=${userId}`
      );
      const data = await response.json();
      const formattedChatHistory = data.map((session) => ({
        id: session.chat_id,
        title: session.chat_title,
        messages: [],
      }));
      setChatHistory(formattedChatHistory);
      if (formattedChatHistory.length > 0) {
        setCurrentChatId(formattedChatHistory[0].id);
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const fetchChatMessages = async (chatId) => {
    const userId = user?.username || "default_user_id";
    try {
      const response = await fetch(
        `https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/getChat?user_id=${userId}&chat_session=${chatId}`
      );
      const data = await response.json();
      const formattedMessages = data.chat.map((msg) => ({
        sender: msg.role === "user" ? "user" : "bot",
        text: replaceResourceKeys(
          msg.content.map((c) => c.text).join("\n"),
          msg.resources || {}
        ),
        timestamp: `${msg.time} ${formatDateWithDots(msg.date)}`,
        documents: msg.documents_used,
        links: Object.entries(msg.resources?.links || {}).map(
          ([key, url]) => `<a href="${url}" target="_blank">${key}</a>`
        ),
      }));
      setChat(formattedMessages);
      updateChatHistory(chatId, formattedMessages);
      setTimeout(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  const updateChatHistory = (chatId, messages) => {
    const updatedHistory = chatHistory.map((chat) =>
      chat.id === chatId ? { ...chat, messages } : chat
    );
    setChatHistory(updatedHistory);
    localStorage.setItem("chatHistory", JSON.stringify(updatedHistory));
  };

  useEffect(() => {
    if (currentChatId !== null) {
      updateChatHistory(currentChatId, chat);
    }
  }, [chat]);

  useEffect(() => {
    if (user) {
      setUser({ name: user.username });
    }
  }, [user]);

  const toCamelCase = (str) => {
    return str.replace(/-./g, (match) => match[1].toUpperCase());
  };

  const getIcon = (iconName) => {
    const camelCaseName = `fa${toCamelCase(
      iconName.charAt(0).toUpperCase() + iconName.slice(1)
    )}`;
    const icon = solidIcons[camelCaseName];
    if (icon) {
      library.add(icon);
      return camelCaseName;
    }
    return null;
  };

  const formatText = (input) => {
    // Decode any escape sequences in the input
    let decodedInput = input
      .replace(/\\u([\dA-F]{4})/gi, (match, grp) =>
        String.fromCharCode(parseInt(grp, 16))
      )
      .replace(/\\t/g, "\t")
      .replace(/\\\"/g, '"');

    // Standardize quotes in the input
    let standardizedInput = decodedInput.replace(/[“”‘’`']/g, '"');

    // Handle newlines
    standardizedInput = standardizedInput.replace(
      /(\n\\n|\\n\n|\\n|\\n\\n|\n)/g,
      "<br/>"
    );

    // Regex pattern to find and log each `[icon name=...]` placeholder
    const iconNameRegex = /\[icon name="([^"]+)"[^\]]*\]/gi;

    // Replace icon placeholders with actual HTML using the name property only
    standardizedInput = standardizedInput.replace(
      iconNameRegex,
      (match, iconName) => {
        const iconClass = getIcon(iconName); // Dynamically load the icon
        if (iconClass) {
          return `<font-awesome-icon icon="${iconClass}" size="sm"></font-awesome-icon>`;
        }
        return ""; // Return an empty string if the icon is not found
      }
    );

    // Use html-react-parser to parse the formatted text
    const parsedText = parse(standardizedInput, {
      replace: (domNode) => {
        if (
          domNode.name &&
          domNode.name.toLowerCase() === "font-awesome-icon"
        ) {
          const iconClass = domNode.attribs.icon;
          return <FontAwesomeIcon icon={solidIcons[iconClass]} size="sm" />;
        }
      },
    });

    return parsedText;
  };

  const getRandomResponse = () => {
    const responses = [
      "This is a random response. This response is designed to be a bit longer to simulate a more realistic reply.",
      "Here's another random message, intended to be longer and more comprehensive. It should give a better feel for how the conversation flows.",
      "Just displaying some random text. This is meant to be a longer sentence that continues for a while to provide ample content for display.",
      "Mocking a response for your message. This one is particularly lengthy and descriptive to ensure that the layout can handle larger blocks of text without issue.",
    ];
    return responses[Math.floor(Math.random() * responses.length)];
  };

  const handleSend = async () => {
    if (message.trim()) {
      const newChat = [
        ...chat,
        {
          sender: "user",
          text: message,
          timestamp: formatDateTime(new Date()),
        },
      ];
      setChat(newChat);
      setLoading(true);
      setMessage("");

      const chatSessionId = currentChatSessionId || generateRandomId();
      setCurrentChatSessionId(chatSessionId);
      const userId = user?.username || "default_user_id";
      let updatedChat = [];

      const queryTime = formatDateTime(new Date());

      try {
        const response = await fetch(
          "https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/qa",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: message,
              user_id: userId,
              chat_session: chatSessionId,
              query_time: queryTime,
            }),
          }
        );

        const data = await response.json();
        const formattedMessage = replaceResourceKeys(
          data.message || getRandomResponse(),
          data.resources || {}
        );

        const botResponse = {
          text: formattedMessage,
          timestamp: data.timestamp || queryTime,
          documents: data.documents_used,
          links: Object.entries(data.resources?.links || {}).map(
            ([key, url]) => `<a href="${url}" target="_blank">${key}</a>`
          ),
        };

        updatedChat = [
          ...newChat,
          {
            sender: "bot",
            text: botResponse.text,
            timestamp: botResponse.timestamp,
            documents: botResponse.documents,
            links: botResponse.links,
          },
        ];
        setChat(updatedChat);

        if (!currentChatId) {
          const newChatEntry = {
            id: chatSessionId,
            title: message,
            messages: updatedChat,
          };
          const updatedHistory = [...chatHistory, newChatEntry];
          setChatHistory(updatedHistory);
          setCurrentChatId(chatSessionId);
          localStorage.setItem("chatHistory", JSON.stringify(updatedHistory));
        } else {
          const updatedHistory = chatHistory.map((chat) =>
            chat.id === currentChatId
              ? { ...chat, messages: updatedChat }
              : chat
          );
          setChatHistory(updatedHistory);
          localStorage.setItem("chatHistory", JSON.stringify(updatedHistory));
        }
        fetchChatHistory();
      } catch (error) {
        console.error("Error sending message:", error);
        const botResponse = getRandomResponse();
        updatedChat = [
          ...newChat,
          { sender: "bot", text: botResponse, timestamp: queryTime },
        ];
        setChat(updatedChat);
      } finally {
        setLoading(false);
        setTimeout(() => {
          chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
      setTimeout(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleAdd = async () => {
    if (question.trim() && answer.trim()) {
      try {
        const response = await fetch(
          "https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/addQuestion",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              question,
              answer,
            }),
          }
        );

        if (response.ok) {
          antdMessage.success("Question added successfully");
          setModalVisible(false);
          setQuestion("");
          setAnswer("");
        } else {
          antdMessage.error("Failed to add question");
        }
      } catch (error) {
        console.error("Error adding question:", error);
        antdMessage.error("Error adding question");
      }
    } else {
      antdMessage.warning("Please fill in both question and answer");
    }
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const formatDateWithDots = (dateString) => {
    return dateString.replace(/\//g, ".");
  };

  const handleLogout = () => {
    signOut();
    setCurrentChatId(null);
    setCurrentChatSessionId(null);
    setChat([]);
    setChatHistory([]);
  };

  const handleNewChat = () => {
    const newChatId = generateRandomId();
    setCurrentChatId(null);
    setCurrentChatSessionId(newChatId);
    setChat([]);
    textAreaRef.current.focus();
  };

  const handleChatSelect = (chatId) => {
    const selectedChat = chatHistory.find((chat) => chat.id === chatId);

    if (selectedChat) {
      setCurrentChatId(chatId);
      setCurrentChatSessionId(chatId);
      fetchChatMessages(chatId);
      setTimeout(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleChatDelete = async (chatId) => {
    const userId = user?.username || "default_user_id";
    try {
      const response = await fetch(
        `https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/deletechat?user_id=${userId}&chat_session=${chatId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        const updatedChatHistory = chatHistory.filter(
          (chat) => chat.id !== chatId
        );
        setChatHistory(updatedChatHistory);
        localStorage.setItem("chatHistory", JSON.stringify(updatedChatHistory));
        if (currentChatId === chatId && updatedChatHistory.length > 0) {
          setCurrentChatId(updatedChatHistory[0].id);
          fetchChatMessages(updatedChatHistory[0].id);
        } else {
          setChat([]);
          setCurrentChatId(null);
        }
        antdMessage.success("Chat deleted successfully");
      } else {
        antdMessage.error("Failed to delete chat");
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
      antdMessage.error("Error deleting chat");
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        Logged in as: <b>{userData.name}</b>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setModalVisible(true)}>
        Add question
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#231F20",
        }}
      >
        <img src={logoWhite} alt="logo" style={{ width: "150px" }} />
        <Dropdown overlay={menu} trigger={["click"]}>
          <Avatar
            style={{ backgroundColor: "#231F20" }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      </Header>

      <Layout>
        <Sider
          width={250}
          style={{
            background: "#fff",
            height: "93vh",
            overflow: "auto",
            padding: "20px",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            onClick={handleNewChat}
            style={{
              marginBottom: "10px",
              backgroundColor: "#231F20",
              color: "white",
              width: "100%",
            }}
          >
            New Chat
          </Button>
          <List
            itemLayout="horizontal"
            dataSource={chatHistory}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    style={{ width: "5px" }}
                    icon={
                      <DeleteOutlined
                        style={{
                          color: "#C2C4C6",
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setChatIdToDelete(item.id);
                      setConfirmVisible(true);
                    }}
                  />,
                ]}
                onClick={() => handleChatSelect(item.id)}
              >
                <List.Item.Meta
                  style={{ width: "100%" }}
                  title={
                    <div
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      {item.title}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Sider>

        <Content
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "calc(100vh - 64px)",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "60%",
              height: "100%",
              overflowY: "auto",
              padding: "10px",
              position: "relative",
            }}
          >
            {Array.isArray(chat) &&
              chat.map((msg, index) => (
                <div
                  key={index}
                  className={`chat-message ${
                    msg.sender === "user" ? "user-message" : "bot-message"
                  }`}
                  style={{
                    alignSelf:
                      msg.sender === "user" ? "flex-end" : "flex-start",
                    maxWidth: msg.sender === "bot" ? "100%" : "70%",
                    background: msg.sender === "user" ? "#e6f7ff" : "#f0f0f0",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Text>
                    {msg.timestamp && (
                      <div>
                        <Text type="secondary">{msg.timestamp}</Text>
                      </div>
                    )}
                    {msg.sender === "bot" &&
                    index === chat.length - 1 &&
                    loading ? (
                      <Spin indicator={<LoadingOutlined />} />
                    ) : (
                      formatText(msg.text)
                    )}
                    {msg.sender === "bot" && msg.documents && (
                      <div style={{ marginTop: "10px" }}>
                        <Text type="secondary">Documents used:</Text>
                        <ul>
                          {msg.documents.map((doc, docIndex) => (
                            <li key={docIndex}>
                              <a
                                href={doc}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {doc}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {msg.sender === "bot" &&
                      msg.links &&
                      msg.links.length > 0 && (
                        <div style={{ marginTop: "10px" }}>
                          <Text type="secondary">Links:</Text>
                          <ul>
                            {msg.links.map((link, linkIndex) => (
                              <li
                                key={linkIndex}
                                dangerouslySetInnerHTML={{ __html: link }}
                              ></li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </Text>
                </div>
              ))}
            <div ref={chatEndRef} />
            {loading && <HypeLoader />}
          </div>
          <div
            style={{
              width: "60%",
              display: "flex",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <TextArea
              rows={1}
              value={message}
              style={{ width: "100%", borderColor: "#231F20" }}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={handleKeyPress}
              className="custom-textarea"
              placeholder="Type your message here..."
              autoSize={{ minRows: 1, maxRows: 6 }}
              ref={textAreaRef}
            />
            <Button
              size="large"
              type="primary"
              onClick={handleSend}
              shape="circle"
              icon={<SendOutlined />}
              disabled={loading}
              style={{ backgroundColor: "#231F20" }}
            />
          </div>
        </Content>
      </Layout>

      <Modal
        title="Add Question and Answer"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="add"
            type="primary"
            style={{ backgroundColor: "#333133" }}
            onClick={handleAdd}
          >
            Add
          </Button>,
        ]}
      >
        <TextArea
          rows={2}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Type your question here..."
          style={{ marginBottom: 10 }}
        />
        <TextArea
          rows={2}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Type your answer here..."
          style={{ marginBottom: 10 }}
        />
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={confirmVisible}
        onOk={() => {
          handleChatDelete(chatIdToDelete);
          setConfirmVisible(false);
        }}
        onCancel={() => setConfirmVisible(false)}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this chat?</p>
      </Modal>
    </Layout>
  );
};

export default ChatMain;
