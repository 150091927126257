import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Alert } from "antd";
import axios from "axios";
import logoBlack from "./assets/logoBlack.png";
import HypeButton from "./common/HypeButton/HypeButton";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        "https://1b9gomow49.execute-api.eu-central-1.amazonaws.com/forgotPassword",
        {
          username: values.username,
        }
      );

      if (response.status === 200) {
        navigate("/reset-password");
      } else {
        setError("An error occurred while processing your request.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "An error occurred while processing your request."
      );
    }
  };

  //   const { user, signIn } = useContext(AuthContext);

  //   if (user) {
  //     return <Navigate to="/" />;
  //   }

  return (
    <div
      style={{
        width: 500,
        margin: "auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <img
        src={logoBlack}
        alt="logo"
        style={{ width: "60%", marginBottom: "40px" }}
      />
      <Form onFinish={handleSubmit} style={{ width: "100%" }}>
        <Form.Item
          style={{ width: "100%" }}
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input
            size="large"
            style={{ width: "100%", borderColor: "#545456" }}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: "0.75rem" }}>
          <HypeButton htmlType="submit" name="Submit" />
        </Form.Item>
      </Form>
      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
}
